var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_vm.bindStauts ? _c('div', [_c('notify-bind-switch', {
    attrs: {
      "notifyStatus": _vm.notifyStatus,
      "notifyType": _vm.notifyType
    }
  })], 1) : _c('span', [_c('font-awesome-icon', {
    staticClass: "mr-2",
    attrs: {
      "icon": "times-circle"
    }
  }), _vm._v(_vm._s(_vm.$t('notification.not_bind')))], 1)]);

}
var staticRenderFns = []

export { render, staticRenderFns }