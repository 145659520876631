<template lang="pug">
    table.push-remind-table
      thead.push-remind-thead
        tr
          th
          th {{ $t('notification.class_remind') }}
          //- 隨選隨上
          //- th {{ $t('notification.notify_on_demend') }}
          th {{ $t('notification.notify_of_right') }}
          th {{ $t('notification.operate') }}
      tbody
        tr
          td
            img.mb-2.line-notify-img-width(:src='lineNotifyLogoUrl' alt='line notify icon')
            h4 {{ $t('notification.line_notify') }}
          td
            notify-bind-status(
              :bindStauts="vipProfileComplete.social_media.bind.line_notify"
              :notifyStatus="notifyInfo.line_notify_booking"
              :notifyType="'lineNotify'")
          //- 隨選隨上
          //- td
          //-   notify-bind-status(
          //-     :bindStauts="vipProfileComplete.social_media.bind.line_notify"
          //-     :notifyStatus="notifyInfo.line_notify_on_demand"
          //-     :notifyType="'lineNotifyDemand'")

          //- 權益通知
          td
            notify-bind-status(
              :bindStauts="vipProfileComplete.social_media.bind.line_notify"
              :notifyStatus="notifyInfo.line_notify_of_right"
              :notifyType="'lineNotifyRight'")
          td
            line-notify-operation(:bindStauts="vipProfileComplete.social_media.bind.line_notify")
        //- 國父革命尚未成功先拿掉 By Asa
        //- tr
        //-   td
        //-     img.mb-2.device-img-width(:src='deviceNotifyLogoUrl' alt='internet icon')
        //-     h4 {{ $t('notification.device_notify') }}
        //-     | ({{ $t('notification.not_support_ios') }})
        //-     br
        //-   td
        //-     device-notify-bind-status
        //-   td ({{ $t('notification.not_support') }})
        //-   td
        //-     b-button.push-button(@click="onAddDeviceNotification()" variant='primary') {{ $t('notification.add_device') }}
        tr
          td
            img.mb-2(:src='officialWebsiteNotifyLogoUrl' alt='wuwow icon' class="logo-img")
            h4 {{ $t('notification.official_website_notify') }}
            | ({{ $t('notification.web_open_only') }})
          td
            notify-bind-switch(:notifyStatus="notifyInfo.notify_booking" :notifyType="'browserNotify'")
          //- 隨選隨上
          //- td
          //-   notify-bind-switch(:notifyStatus="notifyInfo.notify_on_demand" :notifyType="'browserNotifyDemand'")

          //- 權益通知
          td
            notify-bind-switch(:notifyStatus="notifyInfo.notify_of_right" :notifyType="'browserNotifyRight'")

</template>

<script type="text/javascript">
import NotificationPushSettingMixin from './../mixin/notificationPushSettingMixin.vue';
import NotifyBindSwitch from './../notifyBindSwitch';
import NotifyBindStatus from './../notifyBindStatus';
import LineNotifyOperation from './../lineNotifyOperation';
import DeviceNotifyBindStatus from './../deviceNotifyBindStatus';

export default {
  name: 'PcLayout',

  components: {
    NotifyBindSwitch,
    NotifyBindStatus,
    LineNotifyOperation,
    DeviceNotifyBindStatus,
  },

  mixins: [NotificationPushSettingMixin],

  props: {
    vipProfileComplete: {
      type: Object,
      default: function() {
        return {};
      },
    },
    notifyInfo: {
      type: Object,
      default: function() {
        return {};
      },
    },
  },

  data() {
    return {};
  },

  watch: {},

  created() {
    this.getCurrentNotificationPushData();
  },

  methods: {
  },
};
</script>
<style scoped>
.line-notify-img-width{
  width: 50px;
}
.device-img-width{
  width: 45px;
}
.logo-img {
  margin-bottom: 8px;
  width:45px;
}
</style>
