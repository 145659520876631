<template lang="pug">
  skeleton(:loading="loading")
    template(slot="skeleton-template")
      skeleton-template
    template(slot="origin-template")
      .reg-profile
        .wuwow-card
          .wuwow-card-body
            porfile-info(:vipProfileComplete="vipProfileComplete" :vipOwnBD="vipOwnBD")
          .wuwow-card-footer.text-center(v-if="accountRole !== 'parent'")
            router-link.lnk-profile-edit(:to="{ path: '/dojo/account/profile/edit' }")
              font-awesome-icon.mr-2(icon='pen')
              | {{ $t('edit') }}
        notification-push-setting(:vipProfileComplete="vipProfileComplete" @closenotificationpushsettingmodal='closeNotificationPushSettingModal')

</template>

<script type="text/javascript">
import { mapState } from 'vuex';
import skeleton from '@/components/skeleton/index.vue';
import skeletonTemplate from '@/views/dojo/account/profile/skeleton/index.vue';
import lioshutanApi from '@/api';
import porfileInfo from '@/components/vip/profile/profileInfo';
import notificationPushSetting from '@/components/modal/notificationPushSetting';

export default {
  name: 'VipProfile',

  components: {
    skeleton,
    skeletonTemplate,
    porfileInfo,
    notificationPushSetting,
  },

  data() {
    return {
      vipOwnBD: {},
      accountRole: localStorage.getItem('account_role'),
      loading: false,
    };
  },

  computed: {
    ...mapState({
      vipProfileComplete: (state) => state.vipAccount.vipAccountCompleteProfile,
    }),
  },

  async created() {
    this.startLoading();
    await this.getvipAccountCompleteProfile();
    this.endLoading();
  },

  methods: {
    closeNotificationPushSettingModal(res) {},

    async getvipAccountCompleteProfile() {
      // 取得合約時間，供下層 porfile-info 元件於 Vuex 讀取
      const contractElapsedTimeResult = await lioshutanApi.vip.getContractElapsedTime();
      this.$store.dispatch('vipContract/addVipContractElapsedTime', contractElapsedTimeResult.data.elapsed_time);

      // 取得學生完整資料，並存回 Vuex
      const completeProfileResult = await lioshutanApi.vip.getCompleteProfile();
      this.$store.dispatch('vipAccount/addVipAccountCompleteProfile', completeProfileResult.data.profile_base);

      const ownBDResult = await lioshutanApi.vip.getOwnBD();
      this.vipOwnBD = ownBDResult.data;
    },
    startLoading(){
      this.loading = true;
    },

    endLoading(){
      this.loading = false;
    },
  },
};
</script>

<style lang="scss">
@import '@/styles/vips/profile';
</style>
