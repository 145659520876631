<template lang="pug">
div(v-if="vipProfileComplete.profile")
  .are-profile-photo
    avatar.are-profile-photo(:avatarUrl="vipProfileComplete.profile.photo" :avatarRole="'student'")
  h4.h4-profile-level Lv.{{ vipProfileComplete.profile.level }}
  p.txt-profile-contract {{ $t('contract.contract') }}{{ $t('period') }}： {{ vipContractElapsedTime.contract_start }} ~ {{ vipContractElapsedTime.contract_end }}
  table.table.table-responsive-sm.table-profile
    tbody
      tr
        th {{ $t('chinese_name') }}
        td {{ vipProfileComplete.profile.chinese_name|| '--' }}
      tr
        th {{ $t('english_name') }}
        td {{ vipProfileComplete.profile.english_name|| '--' }}
      tr
        th {{ $t('gender') }}
        td {{formatGender(vipProfileComplete.profile.gender) }}
      tr
        th {{ $t('email') }}
        td {{ vipProfileComplete.profile.email|| '--' }}
      tr
        th {{ $t('work_phone')  }}
        td {{ vipProfileComplete.profile.work_phone|| '--' }}
      tr
        th {{ $t('home_phone') }}
        td {{ vipProfileComplete.profile.home_phone || '--'}}
      tr
        th {{ $t('birthday') }}
        td {{ vipProfileComplete.profile.birthday || '--'}}
      tr
        th {{ $t('address') }}
        td {{ formatAddress(vipProfileComplete.address)}}
      tr
        th {{ $t('highest_education')}}
        td {{ transEdu(vipProfileComplete.education.education) || '--'  }}
      tr(v-if="canRecommend")
        th {{ $t('recommend_code') }}
        td
          | {{ vipProfileComplete.recommend.recommend_code }}
          //- 家長按下我要推薦好友的按鈕時 直接導到推薦好友的頁面
          a.btn.btn-primary.ml-3(v-if="accountRole === 'parent'" href="/dojo/vip_mgm" target='_blank')
            | {{ $t('i_want_to') }}{{ $t('recommend_friends') }}
          a.btn.btn-primary.ml-3(v-else :href="mgmRecommendUrl + '?rc=' + vipProfileComplete.recommend.recommend_code + '&nt=' + vipOwnBD.english_name" target='_blank')
            | {{ $t('i_want_to') }}{{ $t('recommend_friends') }}
      //- 先暫時隱藏8/23
      //- tr(v-if="accountRole !== 'parent'")
      //-   th#notification_push {{ $t('line_at') }}
      //-   td
      //-     span.text-success(v-if="vipProfileComplete.social_media.bind.line")
      //-       font-awesome-icon(icon='check-circle')
      //-       | {{ $t('have_joined_friends') }}
      //-     b-button(v-else variant="primary" @click='bindLineAt()') {{ $t('bind_line_at') }}
      //- JR不需要
      //- tr(v-if='vipProfileComplete.card')
      //-   th {{ $t('credit_card') }}
      //-   td {{ vipProfileComplete.card }}
      //- tr(v-if='!vipProfileComplete.card')
      //-   th {{ $t('bind_credit_card') }}
      //-   td
      //-     bind-credit-card
      tr(v-if="accountRole !== 'parent'")
        th(name='notification_push') {{ $t('notification_push') }}
        td
          input.btn.btn-primary(type='button' :value="$t('setting')" v-b-modal.notification_push_setting)

</template>

<script type="text/javascript">
import { mapState } from 'vuex';
import companyConstant from '@/constants/company';
import urlConstant from '@/constants/url';
import BindCreditCard from '@/components/vip/contracts/permanentlyContractInfo/permanentlyPayWay/unpaidSetting/bindCreditCard';
import avatar from '@/components/avatar/index.vue';

export default {
  name: 'VipProfileInfo',

  components: {
    avatar,
    BindCreditCard,
  },

  props: {
    vipProfileComplete: {
      type: Object,
      default: function() {
        return {};
      },
    },

    vipOwnBD: {
      type: Object,
      default: function() {
        return {};
      },
    },
  },

  data() {
    return {
      lineAtAddUrl: companyConstant.INFO.LINE_AT_ADD_URL,
      mgmRecommendUrl: urlConstant.VIP.MGM_RECOMMEND_URL,
      accountRole: localStorage.getItem('account_role'),
    };
  },

  computed: {
    ...mapState({
      vipContractElapsedTime: (state) => state.vipContract.vipContractElapsedTime,
    }),
    // 取得後台權限是否可操作
    canRecommend() {
      return true;
    },
  },

  watch: {},

  created() { },

  methods: {
    transEdu(edu) {
      switch (edu) {
        case 'children':
          return this.$t('children');
        case 'elementary_school':
          return this.$t('elementary_school');
        case 'junior_high_school':
          return this.$t('junior_high_school');
        case 'high_school':
          return this.$t('high_school');
        case 'junior_college':
          return this.$t('junior_college');
        case 'bachelor':
          return this.$t('bachelor');
        case 'master':
          return this.$t('master');
        case 'doctor':
          return this.$t('doctor');
      }
    },

    formatAddress(addressObj) {
      if (!addressObj.postal_code.length) {
        return '--';
      }
      if (!addressObj.country.length) {
        return '--';
      }
      if (!addressObj.city.length) {
        return '--';
      }

      return `${addressObj.postal_code} ${addressObj.country}${addressObj.city}`;
    },

    formatGender(gender) {
      switch (gender) {
        case 'M':
          return '男性';
        case 'F':
          return '女性';
        default:
          return '不願透漏';
      }
    },

    // 打開分頁後綁定
    bindLineAt() {
      window.open(this.lineAtAddUrl);
    },
  },
};
</script>

<style lang="scss"></style>
