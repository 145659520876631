<template lang="pug">
div
  div(v-if="bindStauts")
    notify-bind-switch(:notifyStatus="notifyStatus" :notifyType="notifyType")
  span(v-else)
    font-awesome-icon.mr-2(icon='times-circle')
    | {{ $t('notification.not_bind') }}
</template>

<script type="text/javascript">
import NotifyBindSwitch from './../notifyBindSwitch';
export default {
  name: 'VipNotificationBindStatus',

  components: {
    NotifyBindSwitch,
  },

  props: {
    bindStauts: {
      type: Boolean,
      default: function() {
        return false;
      },
    },

    notifyStatus: {
      type: Number,
      default: function() {
        return 0;
      },
    },

    notifyType: {
      type: String,
      default: function() {
        return '';
      },
    },
  },

  data() {
    return {};
  },

  methods: {
  },
};
</script>
