var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.vipProfileComplete.profile ? _c('div', [_c('div', {
    staticClass: "are-profile-photo"
  }, [_c('avatar', {
    staticClass: "are-profile-photo",
    attrs: {
      "avatarUrl": _vm.vipProfileComplete.profile.photo,
      "avatarRole": 'student'
    }
  })], 1), _c('h4', {
    staticClass: "h4-profile-level"
  }, [_vm._v("Lv." + _vm._s(_vm.vipProfileComplete.profile.level))]), _c('p', {
    staticClass: "txt-profile-contract"
  }, [_vm._v(_vm._s(_vm.$t('contract.contract')) + _vm._s(_vm.$t('period')) + "： " + _vm._s(_vm.vipContractElapsedTime.contract_start) + " ~ " + _vm._s(_vm.vipContractElapsedTime.contract_end))]), _c('table', {
    staticClass: "table table-responsive-sm table-profile"
  }, [_c('tbody', [_c('tr', [_c('th', [_vm._v(_vm._s(_vm.$t('chinese_name')))]), _c('td', [_vm._v(_vm._s(_vm.vipProfileComplete.profile.chinese_name || '--'))])]), _c('tr', [_c('th', [_vm._v(_vm._s(_vm.$t('english_name')))]), _c('td', [_vm._v(_vm._s(_vm.vipProfileComplete.profile.english_name || '--'))])]), _c('tr', [_c('th', [_vm._v(_vm._s(_vm.$t('gender')))]), _c('td', [_vm._v(_vm._s(_vm.formatGender(_vm.vipProfileComplete.profile.gender)))])]), _c('tr', [_c('th', [_vm._v(_vm._s(_vm.$t('email')))]), _c('td', [_vm._v(_vm._s(_vm.vipProfileComplete.profile.email || '--'))])]), _c('tr', [_c('th', [_vm._v(_vm._s(_vm.$t('work_phone')))]), _c('td', [_vm._v(_vm._s(_vm.vipProfileComplete.profile.work_phone || '--'))])]), _c('tr', [_c('th', [_vm._v(_vm._s(_vm.$t('home_phone')))]), _c('td', [_vm._v(_vm._s(_vm.vipProfileComplete.profile.home_phone || '--'))])]), _c('tr', [_c('th', [_vm._v(_vm._s(_vm.$t('birthday')))]), _c('td', [_vm._v(_vm._s(_vm.vipProfileComplete.profile.birthday || '--'))])]), _c('tr', [_c('th', [_vm._v(_vm._s(_vm.$t('address')))]), _c('td', [_vm._v(_vm._s(_vm.formatAddress(_vm.vipProfileComplete.address)))])]), _c('tr', [_c('th', [_vm._v(_vm._s(_vm.$t('highest_education')))]), _c('td', [_vm._v(_vm._s(_vm.transEdu(_vm.vipProfileComplete.education.education) || '--'))])]), _vm.canRecommend ? _c('tr', [_c('th', [_vm._v(_vm._s(_vm.$t('recommend_code')))]), _c('td', [_vm._v(_vm._s(_vm.vipProfileComplete.recommend.recommend_code)), _vm.accountRole === 'parent' ? _c('a', {
    staticClass: "btn btn-primary ml-3",
    attrs: {
      "href": "/dojo/vip_mgm",
      "target": "_blank"
    }
  }, [_vm._v(_vm._s(_vm.$t('i_want_to')) + _vm._s(_vm.$t('recommend_friends')))]) : _c('a', {
    staticClass: "btn btn-primary ml-3",
    attrs: {
      "href": _vm.mgmRecommendUrl + '?rc=' + _vm.vipProfileComplete.recommend.recommend_code + '&nt=' + _vm.vipOwnBD.english_name,
      "target": "_blank"
    }
  }, [_vm._v(_vm._s(_vm.$t('i_want_to')) + _vm._s(_vm.$t('recommend_friends')))])])]) : _vm._e(), _vm.accountRole !== 'parent' ? _c('tr', [_c('th', {
    attrs: {
      "name": "notification_push"
    }
  }, [_vm._v(_vm._s(_vm.$t('notification_push')))]), _c('td', [_c('input', {
    directives: [{
      name: "b-modal",
      rawName: "v-b-modal.notification_push_setting",
      modifiers: {
        "notification_push_setting": true
      }
    }],
    staticClass: "btn btn-primary",
    attrs: {
      "type": "button",
      "value": _vm.$t('setting')
    }
  })])]) : _vm._e()])])]) : _vm._e();

}
var staticRenderFns = []

export { render, staticRenderFns }