export default {
  messages: {
    en: {
      'add_device_success': 'Add a new device successfully!',
      'add_device_fail': 'Add a new device failed!',
      'delete_device_success': 'Delete a device successfully!',
      'delete_device_fail': 'Delete a device failed!',
      'modify_device_success': 'Modify a device successfully!',
      'modify_device_fail': 'Modify a device failed!',
      'not_support_mes': 'Sorry, can not support your device.',
      'new_device': 'New Device',
    },
    tw: {
      'add_device_success': '新增設備成功!',
      'add_device_fail': '新增設備失敗!',
      'delete_device_success': '刪除設備成功!',
      'delete_device_fail': '刪除設備失敗!',
      'modify_device_success': '修改設備成功!',
      'modify_device_fail': '修改設備失敗!',
      'not_support_mes': '您的設備不支援此功能，故無法使用此設定',
      'new_device': '新設備',
    },
  },
};
