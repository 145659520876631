<template lang="pug">
  div
    img( :src='lineNotifyLogoUrl' alt='line notify icon' width='50px')
    span.push-remind-modal-title {{ $t('notification.line_notify') }}
    br
    .push-remind-status-box
      table
        tr
          td.push-remind-type-title
            | {{ $t('notification.class_remind') }}
          td
            notify-bind-status(
              :bindStauts="vipProfileComplete.social_media.bind.line_notify"
              :notifyStatus="notifyInfo.line_notify_booking"
              :notifyType="'lineNotify'")
        //- 選選隨上
        //- tr
        //-   td.push-remind-type-title
        //-     | {{ $t('notification.notify_on_demend') }}
        //-   td
        //-     notify-bind-status(
        //-       :bindStauts="vipProfileComplete.social_media.bind.line_notify"
        //-       :notifyStatus="notifyInfo.line_notify_on_demand"
        //-       :notifyType="'lineNotifyDemand'")
        tr
          td.push-remind-type-title
            | {{ $t('notification.notify_of_right') }}
          td
            notify-bind-status(
              :bindStauts="vipProfileComplete.social_media.bind.line_notify"
              :notifyStatus="notifyInfo.line_notify_of_right"
              :notifyType="'lineNotifyRight'")
        tr
          td.push-remind-type-title
            | {{ $t('notification.operate') }}
          td
            line-notify-operation(:bindStauts="vipProfileComplete.social_media.bind.line_notify")
    br
    br
    //- 國父革命尚未成功先拿掉 By Asa
    //- img(:src='deviceNotifyLogoUrl' alt='internet icon' width='45px')
    //- span.push-remind-modal-title
    //-   | {{ $t('notification.device_notify') }}
    //- br
    //- | ( {{ $t('notification.not_support_ios') }} )
    //- .push-remind-status-box
    //-   table
    //-     tr
    //-       td.push-remind-type-title
    //-         | {{ $t('notification.class_remind') }}
    //-       td
    //-         device-notify-bind-status
    //-     tr
    //-       td.push-remind-type-title
    //-         | {{ $t('notification.operate') }}
    //-       td
    //-         b-button.push-button(@click="onAddDeviceNotification()" variant='primary') {{ $t('notification.add_device') }}
    br
    br
    img(:src='officialWebsiteNotifyLogoUrl' alt='wuwow icon' class="logo-img")
    span.push-remind-modal-title
      | {{ $t('notification.official_website_notify') }}
    br
    | ({{ $t('notification.web_open_only') }})
    .push-remind-status-box
      .push-remind-status-box
        table
          tr
            td.push-remind-type-title
              | {{ $t('notification.class_remind') }}
            td
              notify-bind-switch(:notifyStatus="notifyInfo.notify_booking" :notifyType="'browserNotify'")
          //- 選選隨上
          //- tr
          //-   td.push-remind-type-title
          //-     | {{ $t('notification.notify_on_demend') }}
          //-   td
          //-     notify-bind-switch(:notifyStatus="vipProfileComplete.social_media.bind.notify_on_demand" :notifyType="'browserNotifyDemand'")

          //- 權益通知
          tr
            td.push-remind-type-title
              | {{ $t('notification.notify_of_right') }}
            td
              notify-bind-switch(:notifyStatus="notifyInfo.notify_of_right" :notifyType="'browserNotifyRight'")

</template>

<script type="text/javascript">
import NotificationPushSettingMixin from './../mixin/notificationPushSettingMixin.vue';
import NotifyBindSwitch from './../notifyBindSwitch';
import NotifyBindStatus from './../notifyBindStatus';
import LineNotifyOperation from './../lineNotifyOperation';
import DeviceNotifyBindStatus from './../deviceNotifyBindStatus';

export default {
  name: 'MobileLayout',

  components: {
    NotifyBindSwitch,
    NotifyBindStatus,
    LineNotifyOperation,
    DeviceNotifyBindStatus,
  },

  mixins: [NotificationPushSettingMixin],

  props: {
    vipProfileComplete: {
      type: Object,
      default: function() {
        return {};
      },
    },

    notifyInfo: {
      type: Object,
      default: function() {
        return {};
      },
    },

  },

  data() {
    return {};
  },

  computed: {},

  watch: {},

  created() {},

  methods: {
  },
};
</script>
<style scoped>
.logo-img {
  margin-bottom: 8px;
  width:45px;
}
</style>
