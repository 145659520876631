export default {
  messages: {

    en: {
      unbindLineNotifySuccess: 'Unbind Success',
      unbindLineNotifyFailed: 'Unbind Failed',
    },

    tw: {
      unbindLineNotifySuccess: '已解除Line課前提醒',
      unbindLineNotifyFailed: '解除Line課前提醒失敗',
    },

  },
};
