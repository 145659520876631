var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_vm.notificationPushDeviceDatas && _vm.notificationPushDeviceDatas.length > 0 ? _c('div', _vm._l(_vm.notificationPushDeviceDatas, function (device) {
    return _c('div', {
      key: device.id
    }, [_c('div', {
      staticClass: "device-bind-status"
    }, [_c('b-button', {
      staticClass: "delete-btn p-1 px-2",
      attrs: {
        "variant": "primary"
      },
      on: {
        "click": function ($event) {
          return _vm.onDeletedNotification(device.id);
        }
      }
    }, [_c('font-awesome-icon', {
      attrs: {
        "icon": "times"
      }
    })], 1), _c('b-form-input', {
      staticStyle: {
        "max-width": "150px"
      },
      attrs: {
        "id": "push_edit_name",
        "type": "text",
        "placeholder": _vm.$t("new_device")
      },
      on: {
        "change": function ($event) {
          return _vm.onModifyNotification(device);
        }
      },
      model: {
        value: device.note,
        callback: function ($$v) {
          _vm.$set(device, "note", $$v);
        },
        expression: "device.note"
      }
    }), _vm._l(device.notification_class, function (notification, index) {
      return _c('div', {
        key: index,
        staticClass: "ml-2"
      }, [_c('vue-switch', {
        attrs: {
          "type": "primary",
          "variant": "3d",
          "value": 1,
          "unchecked-value": 0
        },
        on: {
          "change": function ($event) {
            return _vm.onModifyNotification(device);
          }
        },
        model: {
          value: notification.open,
          callback: function ($$v) {
            _vm.$set(notification, "open", $$v);
          },
          expression: "notification.open"
        }
      }), notification.open === 0 ? _c('span', [_vm._v(_vm._s(_vm.$t('close')))]) : notification.open === 1 ? _c('span', [_vm._v(_vm._s(_vm.$t('open')))]) : _vm._e()], 1);
    })], 2), _c('br')]);
  }), 0) : _c('div', [_c('font-awesome-icon', {
    staticClass: "mr-2",
    attrs: {
      "icon": "times-circle"
    }
  }), _c('span', [_vm._v(_vm._s(_vm.$t('notification.not_bind')))])], 1)]);

}
var staticRenderFns = []

export { render, staticRenderFns }