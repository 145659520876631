<template lang="pug">
div
  b-button(v-if='bindStauts' @click='unbindLineNotify()' variant='danger') {{ $t('notification.unbind_line_notify') }}
  div(v-else='')
    b-button.btn-setblue(@click='bindLineNotify()') {{ $t('notification.bind_line_notify') }}
    br
    span ({{$t('notification.refresh_after_bind')}})

</template>

<script type="text/javascript">
import LioshutanApi from '@/api';
import { alertMessage } from '@/utils/sweetAlert.js';
import i18n from './lang.js';

export default {
  name: 'VipLineNotifyOperation',

  components: {},

  // 於 Vip Notification Push Setting Modal，用 api 取得綁定 line Notify 的連結
  inject: ['lineNotifyUrl'],

  props: {
    bindStauts: {
      type: Boolean,
      default: function() {
        return false;
      },
    },
  },

  data() {
    return {};
  },

  i18n,

  mounted() {
  },

  methods: {
    async unbindLineNotify() {
      try {
        const res = await LioshutanApi.notification.unbindLineNotify();
        if (res.status === 200) {
          alertMessage(this.$t('success'), 'success', this.$t('unbindLineNotifySuccess')).then(function() {
            location.reload();
          });
        } else {
          alertMessage(this.$t('failed'), 'error', this.$t('unbindLineNotifyFailed'));
        }
      } catch (err) {
        alertMessage(this.$t('failed'), 'error', this.$t('unbindLineNotifyFailed'));
      }
    },

    bindLineNotify() {
      window.open(this.lineNotifyUrl());
    },

  },
};
</script>
