<template lang="pug">
//- 推播提醒設定 b-modal 原關閉按鈕屬性設定  :ok-title="$t('close_and_save')" ok-only ok-class="push-button"
b-modal#notification_push_setting(size='lg' scrollable='' :title="$t('notification.notification_push_settings')" hide-footer @hide='hideNotificationPushSettingModal()')
  .pc-show
    pc-layout(:vipProfileComplete="vipProfileComplete" :notifyInfo="notifyInfo")
  .mobile-show.text-center
    mobile-layout(:vipProfileComplete="vipProfileComplete" :notifyInfo="notifyInfo")

</template>

<script type="text/javascript">
import pcLayout from './pcLayout';
import mobileLayout from './mobileLayout';
import lioshutanApi from '@/api';

export default {
  name: 'VipNotificationPushSettingModal',

  components: {
    pcLayout,
    mobileLayout,
  },

  props: {
    vipProfileComplete: {
      type: Object,
      default: function() {
        return {};
      },
    },
  },

  data() {
    return {
      notifyInfo: {},
      // 綁定 line Notify 的連結
      lineNotifyUrl: '',
    };
  },

  // vue 2 的資料是非響應式，採用函式
  provide() {
    return {
      lineNotifyUrl: () => {
        return this.lineNotifyUrl;
      },
    };
  },

  async created() {
    this.getNotifyInfo();
    this.getLineNotifyUrl();
  },

  methods: {
    hideNotificationPushSettingModal() {
      this.$emit('closeNotificationPushSettingModal', false);
    },

    async getNotifyInfo() {
      const result = await lioshutanApi.notification.getNotification();
      this.notifyInfo = result.data.data;
      this.$store.dispatch('notification/setCurrentNotificationInfo', this.notifyInfo);
    },

    async getLineNotifyUrl() {
      const res = await lioshutanApi.notification.getLineNotifyUrl();
      this.lineNotifyUrl = res.data.url;
    },
  },
};
</script>
