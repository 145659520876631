<template lang="pug">
  form(:action="appBaseApi + creditCardUrl" method='post')
    input.btn.btn-primary(type='submit' :value="$t('click_me_to_bind_credit')")
    input(type="hidden" name='uid' v-model='vipAccountBaseProfile.user_id')
    input(type="hidden" name='uac' v-model='vipAccountBaseProfile.user_account')

</template>
<script type="text/javascript">
import i18n from './lang.js';
import urlConstant from '@/constants/url';
import { mapState } from 'vuex';

export default {
  name: 'BindCreditCard',

  components: {},

  props: {},

  i18n,

  data() {
    return {
      appBaseApi: process.env.VUE_APP_BASE_API,
      creditCardUrl: urlConstant.VIP.CREDIT_CARD_URL,
    };
  },

  computed: {
    ...mapState({
      vipAccountBaseProfile: (state) => state.vipAccount.vipAccountBaseProfile,
    }),
  },

  methods: {
  },
};
</script>
