<template lang="pug">
div
  div(v-if='notificationPushDeviceDatas && notificationPushDeviceDatas.length > 0')
    div(v-for='device in notificationPushDeviceDatas' :key='device.id')
      .device-bind-status
        b-button.delete-btn.p-1.px-2(variant='primary' @click='onDeletedNotification(device.id)')
          font-awesome-icon(icon='times')
        b-form-input#push_edit_name(v-model='device.note' style='max-width:150px;' type='text' :placeholder='$t("new_device")' @change='onModifyNotification(device)')
        .ml-2(v-for='(notification, index) in device.notification_class' :key='index')
          vue-switch(type='primary' variant='3d' :value='1' v-model='notification.open' :unchecked-value='0' @change="onModifyNotification(device)")
          span(v-if='notification.open === 0') {{ $t('close') }}
          span(v-else-if='notification.open === 1') {{ $t('open') }}
      br
  div(v-else)
    font-awesome-icon.mr-2(icon='times-circle')
    span {{ $t('notification.not_bind') }}

</template>

<script type="text/javascript">
import { mapState } from 'vuex';
import NotifyBindSwitch from './../notifyBindSwitch';
import NotificationPushSettingMixin from './../mixin/notificationPushSettingMixin.vue';

export default {
  name: 'VipDeviceNotifyBindStatus',

  components: {
    NotifyBindSwitch,
  },

  mixins: [NotificationPushSettingMixin],

  props: {
  },

  data() {
    return {};
  },

  computed: {
    ...mapState({
      notificationPushDeviceDatas: (state) => state.notification.notificationPushDeviceDatas,
    }),
  },

  methods: {

  },
};
</script>

<style lang="scss" scoped>
.device-bind-status{
  display: inline-flex;
  margin:0 10px;
}

</style>
