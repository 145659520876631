export default {
  messages: {

    en: {
      click_me_to_bind_credit: 'Click Me To Bind Credit',
    },

    tw: {
      click_me_to_bind_credit: '點我綁定付款信用卡',
    },

  },
};
