var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('vue-switch', {
    attrs: {
      "type": "primary",
      "variant": "3d",
      "value": 1,
      "unchecked-value": 0
    },
    on: {
      "change": function ($event) {
        return _vm.switchBindStatus(_vm.notifyType);
      }
    },
    model: {
      value: _vm.switchStatus,
      callback: function ($$v) {
        _vm.switchStatus = $$v;
      },
      expression: "switchStatus"
    }
  }), _vm.switchStatusDataListener === 0 ? _c('span', [_vm._v(_vm._s(_vm.$t('close')))]) : _vm.switchStatusDataListener === 1 ? _c('span', [_vm._v(_vm._s(_vm.$t('open')))]) : _vm._e()], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }