/* eslint-disable */
import Vue from 'vue';

let PushTokenConfig = {
  apiKey: "AIzaSyCJBmog6MHOSRBuSDsxlcKKDJaZxzrRDKQ",
  authDomain: "taichi-fc391.firebaseapp.com",
  databaseURL: "https://taichi-fc391.firebaseio.com",
  projectId: "taichi-fc391",
  storageBucket: "taichi-fc391.appspot.com",
  messagingSenderId: "792943277280",
  appId: "1:792943277280:web:27c7bb3e66d7d16f650c85"
};

if (!/iPhone/i.test(navigator.userAgent) && 'serviceWorker' in navigator && 'PushManager' in window) {
  firebase.messaging.isSupported();
  firebase.initializeApp(PushTokenConfig);
  Vue.prototype.$messaging = firebase.messaging();
  Vue.prototype.$messaging.usePublicVapidKey('BPap7uEmTy3nq_84IdJAWt5TpFaoKuroqC2xyZzC2jPt-AaXMVpm5C0hEhzBKv6qXmXz5FMkD7kIAN_h3JEmax4')

  navigator.serviceWorker.register('/firebase-messaging-sw.js')
    .then((registration) => {
      Vue.prototype.$messaging.useServiceWorker(registration)
    }).catch(err => {
      console.log(err)
    });
}

export default {

  PushTokenConfig,

  initFireBaseMessage() {
    Vue.prototype.$messaging.onTokenRefresh(() => {
      Vue.prototype.$messaging.getToken().then((refreshedToken) => {
        this.setTokenSentToServer(false)
        this.sendTokenToServer(refreshedToken)
      })
    })
  },

  registerFireBaseMessage() {
    return new Promise((resolve, reject) => {
      Vue.prototype.$messaging.requestPermission().then(() => {
        resolve(this.getToken());
      }).catch((err) => {
        console.log('使用者未同意通知.', err)
        reject(err);
      })

    });
  },

  getToken() {
    return new Promise((resolve, reject) => {
      Vue.prototype.$messaging.getToken().then((currentToken) => {
        if (!currentToken) {
          console.log('無法獲得授權ID，請重新請求')
        };
        resolve(currentToken);
      }).catch((err) => {
        console.log('獲取授權TOKEN失敗 ', err)
        reject(err);
      })

    });

  },
}
