<script type="text/javascript">
import { alertMessage } from '@/utils/sweetAlert.js';
import i18n from './lang';
import LioshutanApi from '@/api';
import VueSwitch from '@/components/switch';
import webPush from '@/utils/webPush';

export default {
  name: 'VipNotificationPushSettingModal',

  components: {
    VueSwitch,
  },

  i18n,

  props: {
    // eslint-disable-next-line
  },

  data() {
    return {
      lineNotifyLogoUrl: 'https://cdn2.wuwow.tw/pictures/socialites/line-notify-logo.png',
      deviceNotifyLogoUrl: 'https://cdn2.wuwow.tw/pictures/socialites/internet-logo.png',
      officialWebsiteNotifyLogoUrl: 'https://cdn2.wuwow.tw/arts/logos/wuwow-logo-icon.svg',
    };
  },

  computed: {},

  watch: {},

  created() {},

  methods: {
    async onAddDeviceNotification() {
      if (
        !/iPhone/i.test(navigator.userAgent) &&
        'serviceWorker' in navigator &&
        'PushManager' in window
      ) {
        try {
          webPush.initFireBaseMessage();
          webPush.registerFireBaseMessage();
          const token = await webPush.getToken();
          const res = await LioshutanApi.notification.queryNotificationPushClasses();
          const params = {
            ty: 'firebase',
            to: token,
            nc: this.getNotificationPushClasses(res.data.data.data),
          };
          await LioshutanApi.notification.addNotificationPushDevice(params);
          this.getCurrentNotificationPushData();
          alertMessage(this.$t('success'), 'success', this.$t('add_device_success'));
        } catch (err) {
          alertMessage(this.$t('failed'), 'error', this.$t('add_device_fail'));
        }
      } else {
        alertMessage(this.$t('failed'), 'error', this.$t('not_support_mes'));
      }
    },

    async onDeletedNotification(id) {
      try {
        await LioshutanApi.notification.deleteNotificationPushDevice(id);
        this.getCurrentNotificationPushData();
        alertMessage(this.$t('success'), 'success', this.$t('delete_device_success'));
      } catch (err) {
        alertMessage(this.$t('failed'), 'error', this.$t('delete_device_fail'));
      }
    },

    async onModifyNotification(pushData) {
      try {
        const params = {
          id: pushData.id,
          nt: pushData.note || this.$t('new_device'),
          nc: await this.getNotificationPushClasses(pushData.notification_class),
        };
        await LioshutanApi.notification.modifyNotificationPushDevice(params);
        this.getCurrentNotificationPushData();
        alertMessage(this.$t('success'), 'success', this.$t('modify_device_success'));
      } catch (err) {
        alertMessage(this.$t('failed'), 'error', this.$t('modify_device_fail'));
      }
    },

    async getNotificationPushClasses(classArray){
      const notificationClassesArray = [];
      classArray.forEach(element => {
        notificationClassesArray.push({
          ty: element.type,
          op: element.open,
        });
      });
      return notificationClassesArray;
    },

    async getCurrentNotificationPushData(){
      const PushData = await LioshutanApi.notification.queryNotificationPushDevice();
      this.$store.dispatch('notification/setNotificationPushDeviceDatas', PushData.data);
    },
  },
};
</script>
