<template lang="pug">
div
  vue-switch(type='primary' variant='3d' :value='1' v-model='switchStatus' :unchecked-value='0' @change="switchBindStatus(notifyType)")
  span(v-if='switchStatusDataListener === 0') {{ $t('close') }}
  span(v-else-if='switchStatusDataListener === 1') {{ $t('open') }}
</template>

<script type="text/javascript">
import lioshutanApi from '@/api';
import vueSwitch from '@/components/switch';
import {
  mapState,
} from 'vuex';

const NOTIFY_TYPE = { LINE_NOTIFY: 'lineNotify', LINE_NOTIFY_DEMAND: 'lineNotifyDemand', LINE_NOTIFY_RIGHT: 'lineNotifyRight', BROWSER_NOTIFY: 'browserNotify', BROWSER_NOTIFY_DEMAND: 'browserNotifyDemand', BROWSER_NOTIFY_RIGHT: 'browserNotifyRight' };

export default {
  name: 'VipNotificationBindSwitch',

  components: {
    vueSwitch,
  },

  props: {
    notifyStatus: {
      type: Number,
      default: function() {
        return 0;
      },
    },

    notifyType: {
      type: String,
      default: function() {
        return '';
      },
    },
  },

  data() {
    return {
      switchStatusDataListener: this.notifyStatus,
    };
  },

  computed: {
    ...mapState({
      currentNotificationInfo: (state) => state.notification.currentNotificationInfo,
    }),

    switchStatus: {
      get: function() {
        return this.notifyStatus;
      },
      set: function(newValue) {
        this.switchStatusDataListener = newValue;
      },
    },
  },

  methods: {
    async switchBindStatus(type) {
      this.getNotifyBindParams(type);
      // 技術債 by Asa
      const params = new URLSearchParams();
      params.append('lineNotifyBooking', this.currentNotificationInfo.line_notify_booking);
      params.append('lineNotifyOnDemand', this.currentNotificationInfo.line_notify_on_demand);
      params.append('notifyBooking', this.currentNotificationInfo.notify_booking);
      params.append('notifyOnDemand', this.currentNotificationInfo.notify_on_demand);
      // 權益通知
      params.append('lineNotifyOfRight', this.currentNotificationInfo.line_notify_of_right);
      params.append('notifyOfRight', this.currentNotificationInfo.notify_of_right);
      try {
        await lioshutanApi.notification.setNotification(params);
      } catch (err) {
        this.switchStatus = this.notifyStatus;
      }
    },

    getNotifyBindParams(type) {
      switch (type) {
        case NOTIFY_TYPE.LINE_NOTIFY:
          this.currentNotificationInfo.line_notify_booking = this.switchStatusDataListener;
          break;
        case NOTIFY_TYPE.LINE_NOTIFY_DEMAND:
          this.currentNotificationInfo.line_notify_on_demand = this.switchStatusDataListener;
          break;
        case NOTIFY_TYPE.LINE_NOTIFY_RIGHT:
          this.currentNotificationInfo.line_notify_of_right = this.switchStatusDataListener;
          break;
        case NOTIFY_TYPE.BROWSER_NOTIFY:
          this.currentNotificationInfo.notify_booking = this.switchStatusDataListener;
          break;
        case NOTIFY_TYPE.BROWSER_NOTIFY_DEMAND:
          this.currentNotificationInfo.notify_on_demand = this.switchStatusDataListener;
          break;
        case NOTIFY_TYPE.BROWSER_NOTIFY_RIGHT:
          this.currentNotificationInfo.notify_of_right = this.switchStatusDataListener;
          break;
        default:
          break;
      }
    },
  },
};
</script>
